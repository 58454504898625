import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('formrow', function ({ empty = true } = { empty: true }) {
    return {
      active: false,
      empty,
    }
  })
})()

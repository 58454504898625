import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('tooltip', function (offset) {
    let hovering = false
    let touchOutsideBlock = false
    return {
      tooltip: null,
      hiding: true,
      async init() {
        let modifiers = [{
          name: 'flip',
          options: {
            allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
          },
        }]
        const toggle = this.$el
        const createPopper = await import('@popperjs/core').then(mod => mod.createPopper).catch(e => console.error(e))

        this.tooltip = toggle.nextElementSibling
        this.tooltip.classList.add('invisible')

        if (offset) modifiers = [...modifiers, {
          name: 'offset',
          options: {
            offset: [0, offset],
          },
        }]

        if (this.tooltip.querySelector('[data-arrow]')) modifiers = [...modifiers, {
          name: 'arrow',
          options: {
            padding: 10,
            element: this.tooltip.querySelector('[data-arrow]'),
          },
        }]

        createPopper(
          toggle,
          this.tooltip,
          { modifiers }
        )
      },
      bindings: {
        toggle: {
          ['@click'](e) {
            if (!e.target.closest('a')) e.preventDefault()
            if (!this.hiding) return
            this.hiding = false
            this.tooltip.classList.remove('invisible')
          },
          ['@click.outside'](e) {
            if (this.hiding || this.tooltip.contains(e.target)) return
            this.hiding = true
            this.tooltip.classList.add('invisible')
          },
          ['@mouseenter']() {
            if (hovering || !this.hiding) return
            hovering = true
            this.hiding = false
            this.tooltip.classList.remove('invisible')
          },
          ['@mouseleave']() {
            const ignore = touchOutsideBlock
            touchOutsideBlock = false

            if (!hovering || this.hiding || ignore) return
            hovering = false
            this.hiding = true
            this.tooltip.classList.add('invisible')
          },
          ['@touchstart.outside'](e) {
            // do not close tooltip if user touches outside of the trigger
            if (!this.tooltip.contains(e.target)) return
            touchOutsideBlock = true
          },
        }
      },
    }
  })
})()

import Alpine from 'alpinejs'

const FETCH_CONFIG = {
  mode: 'cors',
  credentials: 'same-origin',
}

export default (function () {
  Alpine.data('frame', function (href, lazy = false) {
    return {
      content: null,
      insert(target, type = 'div') {
        const element = document.createElement(type)
        element.innerHTML = this.content
        target.appendChild(element)
      },
      replace(target, outer = true) {
        outer
          ? (target.outerHTML = this.content)
          : (target.innerHTML = this.content)
      },
      evtHandler: {
        ['@click.prevent']() {
          this.trigger()
        },
      },
      async trigger() {
        const url = new URL(href, window.location)
        url.searchParams.append('_ajax', true)

        // prevent attacks
        if (
          process.env.NODE_ENV !== 'development' &&
          !(window.location.hostname === url.hostname || !url.hostname.length)
        )
          return

        try {
          const resp = await fetch(url.href, FETCH_CONFIG)
          const content = await resp.text()
          if (content && content.trim().length > 0) this.content = content
        } catch (error) {
          console.warn('Error loading frame:', error)
        }
      },
      init() {
        if (!lazy) this.trigger()
      },
    }
  })
})()

import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('slider', function (conf = {}) {
    return {
      instance: null,
      ready: false,
      enabled: null,
      transition: false,
      transitionToNext: false,
      transitionToPrev: false,
      activeIndex: 0,
      slides: [],
      isActive(index) {
        return this.activeIndex === index
      },
      async load() {
        const root = this.$root
        const slider = this.$refs['slider'] || this.$el
        const [{ Swiper, Autoplay, Navigation, Pagination, EffectFade, Grid }] =
          await Promise.all([
            import('swiper').then((mod) => ({
              Swiper: mod.default,
              Autoplay: mod.Autoplay,
              Navigation: mod.Navigation,
              EffectFade: mod.EffectFade,
              Pagination: mod.Pagination,
              Grid: mod.Grid,
            })),
            import('swiper/css'),
            import('swiper/css/autoplay'),
            import('swiper/css/effect-fade'),
            import('swiper/css/grid'),
            //import('swiper/css/pagination'),
          ])
        const [next, prev] = [
          this.$refs['next'],
          this.$refs['prev']
        ]

        if (conf?.pagination) {
          conf.pagination.clickable = true
          conf.pagination.clickableClass = 'cursor-pointer'
          conf.pagination.bulletActiveClass = '!bg-gray-reddish-700'
          conf.pagination.renderBullet = function(i, className) {
            return `<${this.params.bulletElement || 'span'} class="${className} block h-full bg-gray-reddish-500" style="width:${100 / this.slides.length}%" aria-label="Slide ${i + 1}"></${this.params.bulletElement || 'span'}>`
          }
        }
        if (conf?.pagination?.el) {
          conf.pagination.el = root.querySelector(conf.pagination.el)
        }

        this.instance = new Swiper(slider, {
          modules: [Autoplay, Navigation, Pagination, EffectFade, Grid],
          ...((next && prev) ? {
            navigation: {
              nextEl: next,
              prevEl: prev,
              disabledClass: 'opacity-50'
            },
          } : {}),
          ...conf,
          on: {
            afterInit: (instance) => {
              this.ready = true
              this.enabled = instance.enabled
              this.slides = instance.slides
            },
            enable: () => {
              this.enabled = true
            },
            disable: () => {
              this.enabled = false
            },
            slideChangeTransitionStart: () => {
              this.transition = true
            },
            slideChangeTransitionEnd: () => {
              this.transition = false
            },
            slideNextTransitionStart: () => {
              this.transitionToNext = true
            },
            slideNextTransitionEnd: () => {
              this.transitionToNext = false
            },
            slidePrevTransitionStart: () => {
              this.transitionToPrev = true
            },
            slidePrevTransitionEnd: () => {
              this.transitionToPrev = false
            },
            slideChange: (instance) => {
              this.activeIndex = instance.activeIndex
            },
            update: (instance) => {
              this.slides = instance.slides
            }
          },
        })

        // bubble some events
        ;['slideChange'].map(ev => {
          this.instance.on(ev, () => {
            root.dispatchEvent(new CustomEvent(kebabize(ev), {
              bubbles: true,
              composed: true,
              cancelable: true,
            }))
          })
        })
      },
    }
  })
})()


const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())
import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('dropdown', function (id, selection = null) {
    return {
      open: false,
      selection,
      toggle() {
        if (this.open) return this.close()
        this.$refs.button.focus()
        this.open = true
        this.$dispatch('opendropdown')
      },
      close(focusAfter) {
        if (!this.open) return
        this.open = false
        focusAfter && focusAfter.focus()
        this.$dispatch('closedropdown')
      },
      select(e) {
        const { target } = e
        this.selection = target?.textContent?.trim?.()
        if (id) document.getElementById(id).value = target.dataset.value
        if (this.open) return this.close(this.$refs.button)
      }
    }
  })
})()

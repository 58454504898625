import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

import 'modules/hoverintent'
import 'modules/frame'
import 'modules/centralize'
import 'modules/measure'
import 'modules/nav'
import 'modules/form'
import 'modules/formrow'
import 'modules/dropdown'
import 'modules/tabs'
import 'modules/tooltips'
import 'modules/lightbox'
import 'modules/slider'
import 'modules/accordion'

Alpine.plugin(intersect)
Alpine.store('app', {
  toggleOverflow(flag = false, responsive = false) {
    if (responsive) document.documentElement.classList.toggle('max-md:overflow-hidden', flag)
    else document.documentElement.style.overflow = flag ? null : 'hidden'
  },
})

window.Alpine = Alpine
Alpine.start()

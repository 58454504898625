import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('centralize', function (parentToWatch) {
    return {
      refLeft: 0,
      refWidth: 0,
      targetLeft: 0,
      targetWidth: 0,
      init() {
        const values = () => {
          this.$nextTick(() => {
            this.refLeft = this.$refs['ref'].offsetLeft
            this.refWidth = this.$refs['ref'].offsetWidth
            this.targetLeft = this.$refs['target'].offsetLeft
            this.targetWidth = this.$refs['target'].offsetWidth
          })
        }

        values()

        if (parentToWatch) {
          // listen to container size changes
          try {
            const obs = new ResizeObserver(() => {
              values()
            })

            ;[this.$root.closest(parentToWatch), document.body].forEach(el => obs.observe(el))
          } catch (error) {
          }
        }
      },
    }
  })
})()